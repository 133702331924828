import React from "react";
import { Helmet } from "react-helmet";
import { Link, graphql } from "gatsby";
import { Layout, SectionWrapper } from "../components";

const TagRoute = ({ data, pageContext }) => {
  const posts = data.allMarkdownRemark.edges;
  const postLinks = posts.map((post) => (
    <li key={post.node.fields.slug}>
      {post.node.frontmatter.targeturl ? (
        <a
          href={post.node.frontmatter.targeturl}
          target="_blank"
          className="link"
        >
          {post.node.frontmatter?.title}
        </a>
      ) : (
        <Link to={post.node.fields.slug} className="link">
          <span>
            {(post.node.frontmatter.author || post.node.frontmatter.name) &&
              `${post.node.frontmatter.author} - ${post.node.frontmatter.name}`}
            {post.node.frontmatter?.title}
            {post.node.frontmatter?.stageName}
          </span>
        </Link>
      )}
    </li>
  ));
  const tag = pageContext.tag;
  const title = data.site.siteMetadata.title;
  const totalCount = data.allMarkdownRemark.totalCount;
  const tagHeader = `${totalCount} post${
    totalCount === 1 ? "" : "s"
  } tagged with “${tag}”`;

  return (
    <Layout>
      <Helmet title={`${tag} | ${title}`} />
      <SectionWrapper
        className="container-width-l"
        title={"Thats what we found"}
      >
        <h3>{tagHeader}</h3>
        <ul>{postLinks}</ul>
        <div>
          <Link to="/tags/" className="link">
            <span>Browse all tags</span>
          </Link>
        </div>
      </SectionWrapper>
    </Layout>
  );
};

export default TagRoute;

export const tagPageQuery = graphql`
  query TagPage($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            name
            author
            stageName
            targeturl
          }
        }
      }
    }
  }
`;
